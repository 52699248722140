#navbarNorthContainer {
  max-width: unset;
}

.justify-space-between {
  display: flex;
  align-items: center; 
  justify-content: space-between; 
  width: 100%;
}

.headerContent {
  margin: map-get($spacers, 2);
  margin-right: map-get($spacers, 18);
  height: 100%;
  width: fit-content;
  color: white;
  font-size: x-large;
}

.navbar-nav {
  max-width: 100%;
  height: 100%;
  justify-content: center;
}

.nav-item {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-button {
  box-sizing: content-box;
  width: map-get($spacers, 6);
  height: map-get($spacers, 6);
  background-color: $gray-dark;
  border-radius: 50%;
  padding: map-get($spacers, 1);
  margin: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: filter 300ms;
}

.icon-button:hover {
  filter: brightness(1.2);
}

.dropdown-item {
  display: flex;
  align-items: center;
  gap: map-get($spacers, 2);
}

.menu-item .icon-button {
  margin-right: 0.5rem;
}

.menu-item .icon-button:hover {
  filter: none;
}

.menu-item:hover {
  background-color: $gray;
}

@media (max-width: 991.98px) {
  .nav-toolbar {
    right: map-get($spacers, $key: 2) !important;
  }
}
