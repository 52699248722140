@import "variables/variables.scss";
@import "elements/elements.scss";
@import "general/general.scss";
fieldset {
    background: transparent;
    bottom: 12px !important;
}

.MuiInputAdornment-root {
    margin-bottom: 10px;
}

.MuiInputBase-input {
    padding-top: 8px !important;
    margin-bottom: 4px;
}