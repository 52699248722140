.btns {
  display: flex;
  align-items: center;
  gap: $spacer;
  width: 100%;
}

.skedesplan-btn {
  width: 35%;
  padding: map-get($spacers, 2);
  border-radius: $border-radius;
  color: white;
  border: none;
  cursor: pointer;
  margin: map-get($spacers, 2) 0;
  font-size: 1.5rem;
}

.slider-btns {
  align-items: center;
}

.slider-btn {
  display: flex;
  justify-content: center;
  border: none;
  align-items: center;
  border-radius: $border-radius;
  padding: map-get($spacers, 1) map-get($spacers, 2);
  height: fit-content;
  font-size: large;
  font-weight: bold;

  i {
    margin: 0;
    pointer-events: none;
  }
}

.closeButton {
  background: none;
  border: none;
  color: $gray-dark;
  opacity: 0.5;
  cursor: pointer;
}

.closeButton:hover {
  opacity: 1;
}

.selected-close {
  position: absolute;
  top: map-get($spacers, 2);
  right: map-get($spacers, 2);
}
