* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;

  &::before,
  &::after {
    box-sizing: inherit;
  }
}

body,
input,
button {
  font: $font-weight-normal #{$font-size-base}/#{$line-height-base}
    $font-family-base;
}

body {
  display: flex;
  height: 100vh;
  margin: 0;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  background-color: white;
  color: $gray-dark;
  text-align: left;

  #root {
    flex: 1;
    display: flex;

    .develop-content {
      flex: 1;
      display: flex;
      flex-direction: column;
      padding: 0 !important;
      max-width: unset;
    }
  }

  @media (max-width: 991.98px) {
    &.navbarSouth {
      padding-bottom: calc($spacer * 7 + 6px);
    }
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  cursor: pointer;
  color: white;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
  color: $gray;
}

code {
  font-family: $font-family-monospace;
}

.skedesplaner {
  display: flex;
  height: 80vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.s-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.loginImage {
  padding-top: map-get($spacers, 2);
}

.loginImage:hover {
  cursor: pointer;
}

.delete-container {
  background-color: white;
  width: 50%;
  margin: $spacer;
  margin-bottom: auto;
  padding: map-get($spacers, 4);
  border-radius: $border-radius;
  display: flex;
  flex-direction: column;
  gap: $spacer;
  align-items: stretch;
  justify-content: flex-start;
  box-shadow: $box-shadow;

  .delete-user {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}