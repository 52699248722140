.form {
  background-color: white;
  padding: map-get($spacers, 7);
  padding-bottom: map-get($spacers, 4);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  box-shadow: 5px 5px 15px -1px rgba($gray-dark, 0.8);
  gap: $spacer;
}

.fa-calendar {
  color: white;
}

.window-container{
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .form {
    width: 400px;
    max-width: 80vw;
  }
}

.login-submit {
  width: 100%;
}

.edit-phase-plans{
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .grid {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, fr));
    gap: 0.5rem 1rem;
    padding: 0rem 3rem;

    .header{
      display: none;
    }
    
    .row {
      display: grid;
      color: black;
      margin:0;
      &:hover {
        text-decoration: none;
        color: $gray;
        } 
      } 

      @media (min-width: 1000px) {

        .mobile-header{
          display: none;
        }
    
        .row {
          display: contents;
        }
    
        .header{
          display: grid;
          font-weight: bolder;
        }
    
        .toggleLegendItems{
          grid-column-end: 7;
          padding: 0.5rem 0 0 0
        }
      }
    }

    @media (min-width: 1000px) {
      align-items: center;
      justify-content: center; 

      .grid{
        grid-template-columns: repeat(6, minmax(0, auto));
        padding: 4rem 3rem;
        box-shadow: 5px 5px 15px -1px rgba($gray-dark, 0.8);
      } 
    }
}