.selected {
  position: absolute;
  top: map-get($spacers, 9);
  right: 0;
  min-width: 280px;
  max-width: 320px;
  max-height: 400px;
  background: white;
  padding: map-get($spacers, 4);
  padding-bottom: $spacer;
  margin: map-get($spacers, 4);
  color: $gray-dark;
  box-sizing: border-box;
  overflow-y: auto;
  border: 1px solid $gray-light;
  border-radius: $border-radius;
}

.infoHeader {
  font-weight: bold;
  color: rgba($gray-dark, 0.7);
  font-size: small;
}

.selected-close {
  position: absolute;
  top: map-get($spacers, 2);
  right: map-get($spacers, 2);
}

@media (max-width: 991.98px) {
  .selected {
    left: 0;
    right: 0;
    max-width: unset;
    bottom: 0;
    top: unset;
  }
}
