.legend {
  order: 1;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow-y: auto;
  background-color: white;
  position: fixed;

  .legend-wrapper {
    display: none;
  }

  &.expanded {
    min-width: $legend-width;
    position: unset;
    padding: $spacer map-get($spacers, 2);

    .legend-wrapper {
      display: flex;
      flex-direction: column;
      flex: 1;

      > * {
        margin-bottom: map-get($spacers, 2);
      }
    }
  }

  h3 {
    text-align: center;
    margin-bottom: map-get($spacers, 2);
  }

  .expand-legend {
    display: none;
  }
}

.legend-item,
.legend-item-heading-container {
  display: flex;
  align-items: center;
  gap: map-get($spacers, 2);
  cursor: pointer;

  label {
    flex: 1;
    display: inline-block;
    width: 100px;
    font-size: small;
    margin: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-y: hidden;
    cursor: inherit;
  }

  &:hover {
    input[type="checkbox"] {
      border-color: rgba($gray-dark, 0.6);
    }

    label {
      opacity: 0.8;
    }
  }
}

.legend-item {
  margin-left: 10%;
  font-size: xx-small;
}

.legend-item-header {
  margin-left: 0;
  margin-bottom: 0%;
}

.legend-item-heading {
  margin-bottom: 0%;
}

.toggleLegendItems {
  margin-left: auto;
  font-size: small;
  cursor: pointer;
  border: none !important;
  background-color: transparent;
  padding: map-get($spacers, 1) map-get($spacers, 2);
  color: $gray;
  transition: all 200ms;

  &:active,
  &:focus {
    outline: none;
  }

  i {
    pointer-events: none;
    margin: 0;
    transition: all 200ms;
  }

  &:hover {
    background-color: transparent !important;
    color: $gray-dark;

    i {
      opacity: 0.6;
    }
  }
}

.legend-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: auto;
  margin-bottom: 0;

  p {
    text-align: center;
  }
}

.box {
  height: $spacer;
  width: $spacer;
  box-sizing: border-box;
  border-radius: 3px;

  &.bg-black {
    background-color: $gray-dark;
    margin-left: 2%;
  }
}

input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;
  cursor: pointer;
  margin: 0;
  border: 1.5px solid $gray;
  border-radius: 3px;

  &:checked:after {
    content: "";
    display: block;
    width: 5px;
    height: 10px;
    border: solid #efeff3;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: 0.5px;
    left: 4.5px;
  }
}

.contactButton {
  width: 100%;
}

.MuiInputBase-input {
  color: white !important;
  text-align: center;
}

@media (max-width: 991.98px) {
  .legend {
    // width: 60%;
    padding: 0;
    width: fit-content;
    height: fit-content;

    &.expanded {
      position: absolute;
      top: 0;
      bottom: 0;
      max-width: 60%;
      height: 100%;
      padding-top: map-get($spacers, 1);

      .expand-legend {
        position: absolute;
        right: 0;
        padding: map-get($spacers, 2) $spacer;
      }

      h3 {
        text-align: left;
      }
    }

    .expand-legend {
      display: flex;
      width: fit-content;
      background-color: transparent !important;
      justify-content: center;
      align-items: center;
      border: none;
      margin: 0;
      padding-left: map-get($spacers, 2);
      padding-top: calc($spacer * 0.5 + $spacer * 0.25);
      padding-bottom: calc($spacer * 0.5 + $spacer * 0.25);

      &:active,
      &:focus,
      &:hover {
        background-color: transparent !important;
      }

      i {
        margin: 0;

        &:active,
        &:focus,
        &:hover {
          opacity: 0.6;
        }
      }
    }
  }

  // .legend-overlay {
  //   display: flex;
  //   background-color: rgba(0, 0, 0, 0.3);
  //   width: 100vw;
  //   height: 100vh;
  // }
}
