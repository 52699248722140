#navbarSouthContainer {
  max-width: unset;

  .row {
    flex-wrap: nowrap;
    gap: map-get($spacers, 7);
  }

  @media (max-width: 991.98px) {
    .row {
      flex: 1;
      padding: $spacer;
      margin: 0;
      display: flex;
      flex-wrap: wrap;

      label {
        flex: 1;
        padding: 0;
        order: 2;
      }

      input.custom-range {
        margin-top: $spacer;
        margin-bottom: -$spacer;
      }

      .slider-btns {
        display: none;
      }
    }
  }
}
